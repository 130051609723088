.link-editor {
	display: flex;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10;
	max-width: 400px;
	width: 100%;
	opacity: 0;
	background-color: #fff;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
	border-radius: 0 0 8px 8px;
	transition: opacity 0.5s;
	will-change: transform;
}

.link-editor .button {
	width: 20px;
	height: 20px;
	display: inline-block;
	padding: 6px;
	border-radius: 8px;
	cursor: pointer;
	margin: 0 2px;
}

.link-editor .button.hovered {
	width: 20px;
	height: 20px;
	display: inline-block;
	background-color: #eee;
}

.link-editor .button i,
.actions i {
	background-size: contain;
	display: inline-block;
	height: 20px;
	width: 20px;
	vertical-align: -0.25em;
}

.TableCellResizer__resizer {
	position: absolute;
}

.draggable-block-menu {
	border-radius: 4px;
	padding: 2px 1px;
	cursor: grab;
	opacity: 0;
	position: absolute;
	left: 0;
	top: 0;
	will-change: transform;
}

.draggable-block-menu .icon {
	width: 16px;
	height: 16px;
	opacity: 0.3;
	background-image: url(../images/icons/draggable-block-menu.svg);
}

.draggable-block-menu:active {
	cursor: grabbing;
}

.draggable-block-menu:hover {
	background-color: #efefef;
}

.draggable-block-target-line {
	pointer-events: none;
	background: deepskyblue;
	height: 4px;
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0;
	will-change: transform;
}
